export const DARK_THEME_VARS = {
  '--base-background': 'rgba(24, 26, 27, 0.9)',
  '--base-background-hover-color': 'rgb(34, 36, 38)',
  '--base-font-color': 'rgb(200, 195, 188)',
  '--base-border-color': 'rgb(57, 61, 64)',
  '--table-header-color': 'rgba(232, 230, 227, 0.54)',
  '--comments-border-color': 'rgb(57, 62, 64)',
  '--comments-background-color': '#28282f',
  '--alternate-border-color': 'rgb(57, 61, 64)',
  '--base-header-blue-color': 'rgb(153, 134, 216)',
  '--base-link-blue-color': 'rgb(105, 166, 213)',
  '--alternative-link-blue-color': 'rgb(151, 193, 226)',
  '--navigation-color': 'rgb(232, 230, 227)',
  '--divider-border-color': 'rgba(140, 130, 115, 0.2)',
  '--color-scheme': 'dark',
  '--cup-timer-font-color': 'rgb(232, 230, 227)',
  '--accent-button-color': '#2f4f00',
  '--timer-background-color': 'rgba(45, 36, 156, 0.37)',
  '--toggle-background-color': '#333',
  '--player-cell-transparent-hover-color': 'rgba(24, 26, 27, 0.4)',
  '--player-cell-transparent-normal-color': 'rgba(24, 26, 27, 0)',
  '--rules-hightlight-color': '#333',
  '--button-disabled-color': '#333',
  '--1v1-text-color': 'rgb(178, 172, 162)',
  '--dialog-container-color': '#222',
};
