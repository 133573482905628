export enum Rewards {
  SEASON_ONE_PARTICIPANT = 'Season 1 participant',
  SEASON_TWO_PARTICIPANT = 'Season 2 participant',
  SEASON_THREE_PARTICIPANT = 'Season 3 participant',
  SEASON_FOUR_PARTICIPANT = 'Season 4 participant',
  SEASON_FIVE_PARTICIPANT = 'Season 5 participant',
  SEASON_SIX_PARTICIPANT = 'Season 6 participant',
  SEASON_SEVEN_PARTICIPANT = 'Season 7 participant',
  SEASON_EIGHT_PARTICIPANT = 'Season 8 participant',
  TOP1_VQ3_FIRST_SEASON = 'Top 1 VQ3 (season 1)',
  TOP2_VQ3_FIRST_SEASON = 'Top 2 VQ3 (season 1)',
  TOP3_VQ3_FIRST_SEASON = 'Top 3 VQ3 (season 1)',
  TOP1_CPM_FIRST_SEASON = 'Top 1 CPM (season 1)',
  TOP2_CPM_FIRST_SEASON = 'Top 2 CPM (season 1)',
  TOP3_CPM_FIRST_SEASON = 'Top 3 CPM (season 1)',
  TOP10_VQ3_FIRST_SEASON = 'Top 10 VQ3 (season 1)',
  TOP10_CPM_FIRST_SEASON = 'Top 10 CPM (season 1)',
  TOP1_VQ3_SECOND_SEASON = 'Top 1 VQ3 (season 2)',
  TOP2_VQ3_SECOND_SEASON = 'Top 2 VQ3 (season 2)',
  TOP3_VQ3_SECOND_SEASON = 'Top 3 VQ3 (season 2)',
  TOP1_CPM_SECOND_SEASON = 'Top 1 CPM (season 2)',
  TOP2_CPM_SECOND_SEASON = 'Top 2 CPM (season 2)',
  TOP3_CPM_SECOND_SEASON = 'Top 3 CPM (season 2)',
  TOP10_VQ3_SECOND_SEASON = 'Top 10 VQ3 (season 2)',
  TOP10_CPM_SECOND_SEASON = 'Top 10 CPM (season 2)',
  TOP1_VQ3_THIRD_SEASON = 'Top 1 VQ3 (season 3)',
  TOP2_VQ3_THIRD_SEASON = 'Top 2 VQ3 (season 3)',
  TOP3_VQ3_THIRD_SEASON = 'Top 3 VQ3 (season 3)',
  TOP1_CPM_THIRD_SEASON = 'Top 1 CPM (season 3)',
  TOP2_CPM_THIRD_SEASON = 'Top 2 CPM (season 3)',
  TOP3_CPM_THIRD_SEASON = 'Top 3 CPM (season 3)',
  TOP10_VQ3_THIRD_SEASON = 'Top 10 VQ3 (season 3)',
  TOP10_CPM_THIRD_SEASON = 'Top 10 CPM (season 3)',
  TOP1_VQ3_FOURTH_SEASON = 'Top 1 VQ3 (season 4)',
  TOP2_VQ3_FOURTH_SEASON = 'Top 2 VQ3 (season 4)',
  TOP3_VQ3_FOURTH_SEASON = 'Top 3 VQ3 (season 4)',
  TOP1_CPM_FOURTH_SEASON = 'Top 1 CPM (season 4)',
  TOP2_CPM_FOURTH_SEASON = 'Top 2 CPM (season 4)',
  TOP3_CPM_FOURTH_SEASON = 'Top 3 CPM (season 4)',
  TOP10_VQ3_FOURTH_SEASON = 'Top 10 VQ3 (season 4)',
  TOP10_CPM_FOURTH_SEASON = 'Top 10 CPM (season 4)',
  TOP1_VQ3_FIFTH_SEASON = 'Top 1 VQ3 (season 5)',
  TOP2_VQ3_FIFTH_SEASON = 'Top 2 VQ3 (season 5)',
  TOP3_VQ3_FIFTH_SEASON = 'Top 3 VQ3 (season 5)',
  TOP1_CPM_FIFTH_SEASON = 'Top 1 CPM (season 5)',
  TOP2_CPM_FIFTH_SEASON = 'Top 2 CPM (season 5)',
  TOP3_CPM_FIFTH_SEASON = 'Top 3 CPM (season 5)',
  TOP10_VQ3_FIFTH_SEASON = 'Top 10 VQ3 (season 5)',
  TOP10_CPM_FIFTH_SEASON = 'Top 10 CPM (season 5)',
  THE_BITTER_MAN = 'The Bitter Man',
  THE_CAKE = 'The cake is a lie',
  DDC2018_TOP1 = 'DDC2018 - Top 1',
  DDC2018_TOP2 = 'DDC2018 - Top 2',
  DDC2018_TOP3 = 'DDC2018 - Top 3',
  DDC2018_TOP4 = 'DDC2018 - Top 4',
  DDC2018_PARTICIPANT = 'DDC2018 - Participant',
  DFWC2019_PARTICIPANT = 'DFWC 2019 - Participant',
  DFWC2021_PARTICIPANT = 'DFWC 2021 - Participant',
  DFWC2019_WINNER_VQ3 = 'DFWC 2019 - Winner (VQ3)',
  DFWC2019_WINNER_CPM = 'DFWC 2019 - Winner (CPM)',
  DFWC2019_TOP2_VQ3 = 'DFWC 2019 - Top 2 (VQ3)',
  DFWC2019_TOP2_CPM = 'DFWC 2019 - Top 2 (CPM)',
  DFWC2019_TOP3_VQ3 = 'DFWC 2019 - Top 3 (VQ3)',
  DFWC2019_TOP3_CPM = 'DFWC 2019 - Top 3 (CPM)',
  PG_ULTIMATE = 'PG Ultimate',
  DFWC_TRAINING_CUP_WINNER = 'DFWC Training Cup Winner',
  DFWC2021_WINNER_VQ3 = 'DFWC 2021 - Winner (VQ3)',
  DFWC2021_TOP2_VQ3 = 'DFWC 2021 - Top 2 (VQ3)',
  DFWC2021_TOP3_VQ3 = 'DFWC 2021 - Top 3 (VQ3)',
  DFWC2021_WINNER_CPM = 'DFWC 2021 - Winner (CPM)',
  DFWC2021_TOP2_CPM = 'DFWC 2021 - Top 2 (CPM)',
  DFWC2021_TOP3_CPM = 'DFWC 2021 - Top 3 (CPM)',
  SDCUK20_WINNER_VQ3 = 'SDC-UK20 - Winner (VQ3)',
  SDCUK20_TOP2_VQ3 = 'SDC-UK20 - Top 2 (VQ3)',
  SDCUK20_TOP3_VQ3 = 'SDC-UK20 - Top 3 (VQ3)',
  SDCUK20_WINNER_CPM = 'SDC-UK20 - Winner (CPM)',
  SDCUK20_TOP2_CPM = 'SDC-UK20 - Top 2 (CPM)',
  SDCUK20_TOP3_CPM = 'SDC-UK20 - Top 3 (CPM)',
  FPSCUP_WINNER_VQ3 = 'FPS Cup - Winner (VQ3)',
  FPSCUP_TOP2_VQ3 = 'FPS Cup - Top 2 (VQ3)',
  FPSCUP_TOP3_VQ3 = 'FPS Cup - Top 3 (VQ3)',
  FPSCUP_WINNER_CPM = 'FPS Cup - Winner (CPM)',
  FPSCUP_TOP2_CPM = 'FPS Cup - Top 2 (CPM)',
  FPSCUP_TOP3_CPM = 'FPS Cup - Top 3 (CPM)',
  FPSCUP02_WINNER_VQ3 = 'FPS Winter Cup - Winner (VQ3)',
  FPSCUP02_TOP2_VQ3 = 'FPS Winter Cup - Top 2 (VQ3)',
  FPSCUP02_TOP3_VQ3 = 'FPS Winter Cup - Top 3 (VQ3)',
  FPSCUP02_WINNER_CPM = 'FPS Winter Cup - Winner (CPM)',
  FPSCUP02_TOP2_CPM = 'FPS Winter Cup - Top 2 (CPM)',
  FPSCUP02_TOP3_CPM = 'FPS Winter Cup - Top 3 (CPM)',
  TOP1_VQ3_SIXTH_SEASON = 'Top 1 VQ3 (season 6)',
  TOP2_VQ3_SIXTH_SEASON = 'Top 2 VQ3 (season 6)',
  TOP3_VQ3_SIXTH_SEASON = 'Top 3 VQ3 (season 6)',
  TOP1_CPM_SIXTH_SEASON = 'Top 1 CPM (season 6)',
  TOP2_CPM_SIXTH_SEASON = 'Top 2 CPM (season 6)',
  TOP3_CPM_SIXTH_SEASON = 'Top 3 CPM (season 6)',
  TOP10_VQ3_SIXTH_SEASON = 'Top 10 VQ3 (season 6)',
  TOP10_CPM_SIXTH_SEASON = 'Top 10 CPM (season 6)',
  HANGTIME3_WINNER_CPM = 'Hangtime3 Cup - Winner (CPM)',
  HANGTIME3_TOP2_CPM = 'Hangtime3 Cup - Top 2 (CPM)',
  HANGTIME3_WINNER_VQ3 = 'Hangtime3 Cup - Winner (VQ3)',
  HANGTIME3_TOP2_VQ3 = 'Hangtime3 Cup - Top 2 (VQ3)',
  TOP1_VQ3_SEVENTH_SEASON = 'Top 1 VQ3 (season 7)',
  TOP2_VQ3_SEVENTH_SEASON = 'Top 2 VQ3 (season 7)',
  TOP3_VQ3_SEVENTH_SEASON = 'Top 3 VQ3 (season 7)',
  TOP1_CPM_SEVENTH_SEASON = 'Top 1 CPM (season 7)',
  TOP2_CPM_SEVENTH_SEASON = 'Top 2 CPM (season 7)',
  TOP3_CPM_SEVENTH_SEASON = 'Top 3 CPM (season 7)',
  TOP10_VQ3_SEVENTH_SEASON = 'Top 10 VQ3 (season 7)',
  TOP10_CPM_SEVENTH_SEASON = 'Top 10 CPM (season 7)',
}
