export const LIGHT_THEME_VARS = {
  '--base-background': '#ffffff',
  '--base-background-hover-color': '#eeeeee',
  '--base-font-color': '#333333',
  '--base-border-color': '#cccccc',
  '--table-header-color': '#333333',
  '--comments-border-color': '#dedede',
  '--comments-background-color': 'whitesmoke',
  '--alternate-border-color': '#e0e0e0',
  '--base-header-blue-color': '#392676',
  '--base-link-blue-color': '#337ab7',
  '--alternative-link-blue-color': '#1e476b',
  '--navigation-color': '#000000',
  '--divider-border-color': 'rgba(0, 0, 0, 0.12)',
  '--color-scheme': 'light',
  '--cup-timer-font-color': '#ffffff',
  '--accent-button-color': '#ffd740',
  '--timer-background-color': 'rgb(74, 67, 164, 0.7)',
  '--toggle-background-color': '#ccc',
  '--player-cell-transparent-hover-color': 'rgba(255, 255, 255, 0.4)',
  '--player-cell-transparent-normal-color': 'rgba(255, 255, 255, 0)',
  '--rules-hightlight-color': '#e8e8e8',
  '--button-disabled-color': 'rgba(0,0,0,.12)',
  '--1v1-text-color': '#555',
  '--dialog-container-color': 'white',
};
